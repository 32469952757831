/*
 * Custom
 */
$( document ).ready(function() {

    // Open consult

    $('.header_consult .consult_open').on('click', function () {
        $('.header_consult').addClass('open');
    });

    $('.header_consult .consult_close').on('click', function () {
        $('.header_consult').removeClass('open');
    });

    // Mobile menu

    $('.nav_mobile .nav_mobile_open').on('click', function () {
        $('.nav_mobile .menu').addClass('menu_open');
        $('.over').addClass('over_visible');
        $('body').addClass('overflow');
    });

    $('.over').on('click', function () {
        $('.nav_mobile .menu').removeClass('menu_open');
        $(this).removeClass('over_visible');
        $('body').removeClass('overflow');
    });

    // Price button

    $('.service_btn a').click(function() {
        $('html,body').animate({'scrollTop' : 0},1000);
        $('.consult_open').click();
    });



});